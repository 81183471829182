import { Fragment, ReactNode, useEffect, useMemo, useState } from "react";
import {
  ComputerDesktopIcon,
  ExclamationCircleIcon,
  MoonIcon,
  SunIcon,
} from "@heroicons/react/24/outline";
import Logo from "./Logo";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import SharedEditEmbed from "./SharedEditEmbed";
import { Provider } from "./HomeBase";
import { SidebarLayout } from "./catalyst/sidebar-layout";
import { Navbar } from "./catalyst/navbar";
import {
  Sidebar,
  SidebarBody,
  SidebarHeader,
  SidebarItem,
  SidebarLabel,
  SidebarSection,
} from "./catalyst/sidebar";
import { Heading } from "./catalyst/heading";
import { useModalPicker } from "./ModalPicker";
import { useDarkMode } from "./DarkModeProvider";
import { Divider } from "./catalyst/divider";

const navigation = [
  {
    name: "Report a Problem",
    href: "https://forms.reform.app/jpVWm8/xxt-feedback/KW3kXP",
    icon: ExclamationCircleIcon,
  },
];

export default function SharedHome() {
  // const { pathname } = useLocation();
  // const { guid } = useParams();
  // const isEmbed = pathname?.startsWith("/embed/");
  const [title, setTitle] = useState<ReactNode>("Untitled");
  const [pageKey, setPageKey] = useState("");

  const value = useMemo(
    () => ({
      title,
      setTitle,
      pageKey,
      setPageKey,
    }),
    [title, pageKey]
  );
  const picker = useModalPicker();
  const { setTheme } = useDarkMode();
  useEffect(() => {
    console.log("My new title is", title);
  }, [title]);
  return (
    <Provider value={value}>
      <Helmet>
        <title>{title} | ScriptTag by State Change</title>
      </Helmet>
      <SidebarLayout
        navbar={<Navbar></Navbar>}
        sidebar={
          <Sidebar>
            <SidebarHeader>
              <Fragment>
                <Link to="/">
                  <div className="flex flex-row gap-x-2">
                    <Logo className="h-8 w-8 mt-2" />
                    <div className="flex-col flex">
                      <div className="text-lg font-extrabold">ScriptTag</div>
                      <div className="text-xs font-semibold -mt-1">
                        by State Change
                      </div>
                    </div>
                  </div>
                </Link>
                {/* <ChevronRightIcon
                        className={clsx(
                          "size-3 transition-transform duration-150",
                          active ? "rotate-90" : "rotate-0",
                        )}
                      /> */}
              </Fragment>
            </SidebarHeader>
            <SidebarBody>
              <SidebarSection className="sidebar-portal" />
              <SidebarSection className="bottom-10 absolute">
                {navigation.map((item) => (
                  <SidebarItem
                    key={item.name}
                    href={item.href}
                    target="_blank"
                    // icon={item.icon}
                  >
                    {<item.icon className="h-4 w-4" />}
                    <SidebarLabel>{item.name}</SidebarLabel>
                  </SidebarItem>
                ))}

                <SidebarItem
                  onClick={async () => {
                    console.log("clicked");
                    const result = await picker({
                      title: "Select Theme",
                      options: [
                        {
                          title:
                            "Light" +
                            (localStorage.theme === "light"
                              ? " (current)"
                              : ""),
                          key: "light",
                          icon: <SunIcon />,
                        },
                        {
                          title:
                            "Dark" +
                            (localStorage.theme === "dark" ? " (current)" : ""),
                          key: "dark",
                          icon: <MoonIcon />,
                        },
                        {
                          title:
                            "System" +
                            (localStorage.theme === "system"
                              ? " (current)"
                              : ""),
                          key: "system",
                          icon: <ComputerDesktopIcon />,
                        },
                      ],
                    });
                    console.log("result", result);
                    if (result && result !== "cancel") {
                      localStorage.theme = result;
                      setTheme(localStorage.theme);
                    }
                    // setTheme(theme === "dark" ? "light" : "dark");
                  }}
                >
                  {localStorage.theme === "dark" ? (
                    <MoonIcon className="h-4 w-4" />
                  ) : localStorage.theme === "light" ? (
                    <SunIcon className="h-4 w-4" />
                  ) : (
                    <ComputerDesktopIcon className="h-4 w-4" />
                  )}
                  <SidebarLabel>Toggle Dark Mode</SidebarLabel>
                </SidebarItem>
              </SidebarSection>
            </SidebarBody>
          </Sidebar>
        }
      >
        {title && (
          <div className="flex flex-1">
            {typeof title === "string" ? (
              <Fragment>
                <div className="flex flex-col w-full gap-y-2">
                  <Heading level={1}>{title}</Heading>
                  <Divider />
                </div>
              </Fragment>
            ) : (
              title
            )}
          </div>
        )}
        <SharedEditEmbed />
      </SidebarLayout>
    </Provider>
  );
}
