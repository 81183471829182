import { FC, useState, useEffect, useRef } from "react";
import MonacoEditor from "@monaco-editor/react";
import { EmbedData, useBase64Uri, useEmbed } from "./EmbedProviderBase";
import { useHome } from "./HomeBase";
import SidebarPortal from "./SidebarPortal";
import { toast } from "react-toastify";
import {
  ClipboardDocumentIcon,
  CloudArrowDownIcon,
} from "@heroicons/react/24/outline";
import { useAuthenticatedFetch, useAuthentication } from "./Authenticator";
import { useNavigate, useLocation } from "react-router-dom";
import WewebEmbed from "./WewebEmbed";
import EmbedTag from "./EmbedTag";
import CTA2 from "./CTA2";
import { XanoClient } from "@xano/js-sdk/lib/client";
import { SidebarItem, SidebarLabel } from "./catalyst/sidebar";
let xanoChannel: any | undefined;
const CTAHEIGHT = 300;
const SharedEditor: FC = () => {
  const { pathname } = useLocation();

  const isEmbed = pathname?.startsWith("/embed/");
  const { body, data, isLoading, setBody, type, unsaved, name, refetch } =
    useEmbed();
  const rootRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(
    window.innerHeight - (rootRef.current?.offsetTop || 0)
  );
  const [width, setWidth] = useState(0);
  const { isAuthenticated } = useAuthentication();
  useEffect(() => {
    if (!rootRef.current || !rootRef.current.parentElement?.parentElement)
      return;
    const ho = new ResizeObserver(() => {
      setHeight(
        window.innerHeight -
          (rootRef.current?.offsetTop || 0) -
          (isAuthenticated || isEmbed ? 0 : CTAHEIGHT)
      );
    });
    ho.observe(rootRef.current.parentElement);
    const wo = new ResizeObserver((entries) => {
      console.log("Width detected", entries[0]);
      const newWidth = entries[0].contentRect.width; // - entries[0].contentRect.left;
      console.log("I will set width to ", newWidth);
      setWidth(newWidth);
    });
    wo.observe(rootRef.current.parentElement.parentElement);
    return () => {
      ho.disconnect();
      wo.disconnect();
    };
  }, [data, isAuthenticated, isEmbed]);
  const { setTitle } = useHome();
  useEffect(() => {
    if (name) {
      const newTitle =
        name +
        (type === "javascript"
          ? ".js"
          : type === "css"
            ? ".css"
            : type === "html"
              ? ".html"
              : "") +
        (isEmbed ? "" : data?.is_shared ? " (shared)" : " (read-only)");
      setTitle(newTitle);
      console.log("Setting title to", newTitle);
    } else setTitle("Untitled Document");
  }, [name, setTitle, unsaved, type, data, isEmbed]);
  const authenticatedFetch = useAuthenticatedFetch();
  const navigate = useNavigate();
  const refetchReference = useRef(refetch);
  refetchReference.current = refetch;

  useEffect(() => {
    (async () => {
      const options = {
        instanceBaseUrl: import.meta.env.VITE_XANO_BASE_URL,
        realtimeConnectionHash: import.meta.env.VITE_XANO_RT_HASH,
      };
      if (!data?.guid) return;
      if (xanoChannel) xanoChannel.destroy();

      const xanoClient = new XanoClient(options);
      const channel = `statechange/${btoa(data?.guid)}`;
      xanoChannel = xanoClient.channel(channel);
      xanoChannel.on(
        (action: { action: string; payload: Record<string, any> }) => {
          if (action.action === "event") {
            if (!action.payload?.data) return;
            refetchReference.current();
          }
        }
      );
    })();
  }, [data?.guid]);

  const getBase64 = useBase64Uri();
  if (!data) return null;
  // if (!width) return null;
  return (
    <div
      className="relative"
      ref={rootRef}
      style={{
        height: height - (isEmbed ? 0 : 25),
        width: width,
        left: 0,
      }}
    >
      <SidebarPortal>
        <EmbedTag />

        <WewebEmbed />
        <SidebarItem
          onClick={() => {
            navigator.clipboard.writeText(getBase64());
            toast.success("Copied base64 data URI to clipboard");
          }}
        >
          <ClipboardDocumentIcon className="h-4 w-4" aria-hidden="true" />
          <SidebarLabel>Copy Base64 data URI</SidebarLabel>
        </SidebarItem>
        {isAuthenticated && (
          <SidebarItem
            onClick={async () => {
              const response = await authenticatedFetch("/me/embed", {
                method: "POST",
                body: JSON.stringify({
                  name,
                  type,
                  body,
                }),
              });
              if (response.ok) {
                const data: EmbedData = await response.json();
                toast.success("Cloned to your workspace");
                navigate(`/${data.id}`);
              } else {
                toast.error("Failed to clone");
              }
            }}
          >
            <ClipboardDocumentIcon className="h-4 w-4" aria-hidden="true" />
            <SidebarLabel>Clone to my workspace</SidebarLabel>
          </SidebarItem>
        )}
        <SidebarItem
          onClick={() => {
            refetch();
          }}
        >
          <CloudArrowDownIcon className="h-4 w-4" aria-hidden="true" />
          <SidebarLabel>Get updates now</SidebarLabel>
        </SidebarItem>
      </SidebarPortal>

      <MonacoEditor
        // height="90vh"

        className={isEmbed ? "" : "my-2"}
        language={type || "javascript"}
        theme="vs-dark"
        value={body}
        options={{ wordWrap: "on", readOnly: true }}
        onChange={(body) => {
          if (!isLoading) {
            console.log("I am updating the body");
            setBody(typeof body !== "undefined" ? body : "");
          }
        }}
      />
      {!isAuthenticated && !isEmbed && (
        <div className=" overflow-hidden" style={{ height: CTAHEIGHT }}>
          <CTA2 />
        </div>
      )}
    </div>
  );
};

export default SharedEditor;
