import { FC, useCallback, useEffect, useMemo } from "react";
import { Field, Formik, Form } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import {
  useAuthenticatedFetch,
  useAuthenticatedQuery,
  useMe,
} from "./Authenticator";
import { SCMProject } from "./Projects";
import { EmbedData } from "./EmbedProviderBase";
import EmbedList from "./EmbedList";
import AddEmbed from "./AddEmbed";
import { useHome } from "./HomeBase";
import { toast } from "react-toastify";
import SidebarPortal from "./SidebarPortal";
import { useProjectRemove } from "./useProjectRemove";
import { TrashIcon } from "@heroicons/react/24/outline";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { CloudArrowDownIcon } from "@heroicons/react/20/solid";
import { useAlert } from "./Alert";
import { SidebarItem, SidebarLabel, SidebarSection } from "./catalyst/sidebar";
import { Fieldset, Label } from "./catalyst/fieldset";
import { Input } from "./catalyst/input";
import { Button } from "./catalyst/button";
import { Text } from "./catalyst/text";
import { Heading } from "./catalyst/heading";
const RAY_ID = 4;
const Project: FC = () => {
  const { setTitle } = useHome();
  const { data: me } = useMe();
  const meId = useMemo(() => me?.id, [me?.id]);
  const { id } = useParams();
  const idNumber = parseInt(id || "0");
  const { data, refetch, error } = useAuthenticatedQuery<{
    project: SCMProject;
    embeds: EmbedData[];
  }>(`/me/project/${id}`, undefined, typeof id === "undefined");
  useEffect(() => {
    setTitle(data?.project?.name || "Project");
  }, [setTitle, data?.project?.name]);
  const navigate = useNavigate();
  useEffect(() => {
    if (error) {
      toast.error("Error loading project");
      navigate("/");
    }
  }, [error, navigate]);
  const fetch = useAuthenticatedFetch();
  const javascripts = data?.embeds.filter((e) => e.type === "javascript");
  const cssscripts = data?.embeds.filter((e) => e.type === "css");
  const htmlscripts = data?.embeds.filter((e) => e.type === "html");
  const remove = useProjectRemove();
  const unshare = useCallback(
    async (userId: number) => {
      const body = JSON.stringify({
        remove_user_id: userId,
        project_id: id,
      });
      await fetch("/me/share", { body, method: "POST" });
      refetch();
    },
    [refetch, fetch, id]
  );
  // const share = useCallback(
  //   async (userId: number) => {
  //     const body = JSON.stringify({
  //       add_user_id: userId,
  //       project_id: id,
  //     });
  //     await fetch("/me/share", { body, method: "POST" });
  //     refetch();
  //   },
  //   [refetch, fetch, id]
  // );
  const transfer = useCallback(
    async (userId: number) => {
      const body = JSON.stringify({
        user_id: userId,
        project_id: id,
      });
      await fetch("/me/transfer", { body, method: "POST" });
      refetch();
    },
    [refetch, fetch, id]
  );
  const { confirm } = useAlert();

  return (
    <div className="flex-col space-y-4">
      {data && (
        <SidebarPortal>
          <div className="p-4">
            <Formik
              initialValues={{
                projectName: data?.project.name,
                projectDescription: data?.project.description,
              }}
              onSubmit={async (values) => {
                const response = await fetch(`/me/project/${id}`, {
                  method: "POST",
                  body: JSON.stringify({
                    name: values.projectName,
                    description: values.projectDescription,
                  }),
                });
                if (response.ok) {
                  toast.success(values.projectName + " updated!");
                  await refetch();
                } else {
                  toast.error("Failed to update project");
                }
              }}
            >
              {({ isSubmitting, dirty }) => (
                <Form>
                  <Fieldset className="space-y-4">
                    <div>
                      <Label htmlFor="projectName">Project Name</Label>
                      <Field
                        as={Input}
                        name="projectName"
                        id="projectName"
                        placeholder="My Project"
                      />
                    </div>

                    <div>
                      <Label htmlFor="projectDescription">Description</Label>
                      <Field
                        as={Input}
                        name="projectDescription"
                        id="projectDescription"
                        placeholder="Project description..."
                      />
                    </div>

                    <Button type="submit" disabled={isSubmitting || !dirty}>
                      {isSubmitting ? "Saving..." : "Save Changes"}
                    </Button>
                  </Fieldset>
                </Form>
              )}
            </Formik>
          </div>

          <SidebarSection>
            <SidebarItem
              onClick={() => {
                remove(idNumber, () => {
                  navigate("/");
                });
              }}
            >
              <TrashIcon className="h-4 w-4" data-slot="icon" />
              <SidebarLabel>Delete</SidebarLabel>
            </SidebarItem>

            {(!!javascripts?.length ||
              !!cssscripts?.length ||
              htmlscripts?.length) && (
              <SidebarItem
                onClick={() => {
                  const archive = new JSZip();
                  if (javascripts)
                    javascripts.forEach((js) => {
                      archive.file(js.name + ".js", js.body);
                    });
                  if (cssscripts)
                    cssscripts.forEach((css) => {
                      archive.file(css.name + ".css", css.body);
                    });
                  if (htmlscripts)
                    htmlscripts.forEach((css) => {
                      archive.file(css.name + ".html", css.body);
                    });
                  archive.generateAsync({ type: "blob" }).then((content) => {
                    saveAs(content, data?.project.name + ".zip");
                  });
                }}
              >
                <CloudArrowDownIcon className="h-4 w-4" data-slot="icon" />
                <SidebarLabel>Download Archive</SidebarLabel>
              </SidebarItem>
            )}
          </SidebarSection>

          <SidebarSection>
            <Text>
              {!data?.project?.shared_with || !data?.project?.shared_with.length
                ? "Not Currently Shared"
                : `Owned by ${
                    me?.id === data?.project?.user_id
                      ? "Me"
                      : data?.project?._user.email
                  }`}
            </Text>

            <div className="mt-4 p-4 rounded-lg border border-zinc-950/10 dark:border-white/10">
              {data?.project?.shared_with &&
                data?.project?.shared_with.length > 0 && (
                  <div className="space-y-4">
                    <Text className="font-semibold">Shared With</Text>
                    <div className="space-y-2">
                      {data?.project?.shared_with.map(({ id, email }) => (
                        <div
                          key={id}
                          className="p-3 rounded-lg bg-zinc-950/[2.5%] dark:bg-white/5"
                        >
                          <div
                            title={email}
                            className="text-sm font-medium truncate text-zinc-950 dark:text-white"
                          >
                            {email} {id === meId && "(me)"}
                          </div>
                          {meId === data.project?.user_id && (
                            <div className="mt-2 flex gap-2">
                              <Button
                                // size="sm"
                                color="red"
                                onClick={() => unshare(id)}
                              >
                                Unshare
                              </Button>
                              {meId !== id && (
                                <Button
                                  // size="sm"
                                  onClick={async () => {
                                    const result = await confirm({
                                      title: "Transfer project ownership?",
                                      message:
                                        "This will transfer ownership of this project to the user you select. You will no longer be able to manage this project.",
                                    });
                                    if (result === "accept") transfer(id);
                                  }}
                                >
                                  Transfer
                                </Button>
                              )}
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                )}

              <div className="mt-6">
                <Text className="font-semibold">Share:</Text>
                {data?.project?.user_id === RAY_ID ? (
                  <Text className="mt-2">Ray's projects cannot be shared</Text>
                ) : (
                  <Formik
                    initialValues={{ email: "" }}
                    onSubmit={async (values, { setSubmitting, resetForm }) => {
                      try {
                        const response = await fetch("/me/share", {
                          method: "POST",
                          body: JSON.stringify({
                            email: values.email,
                            project_id: id,
                          }),
                        });
                        if (response.ok) {
                          toast.success("Shared successfully");
                          resetForm();
                          refetch();
                        } else {
                          toast.error("Failed to share");
                        }
                      } catch (e) {
                        toast.error("Failed to share");
                      }
                      setSubmitting(false);
                    }}
                  >
                    {({ isSubmitting, dirty }) => (
                      <Form className="mt-4">
                        <Fieldset>
                          <div>
                            <Label htmlFor="email">
                              Share with a Statechange Member
                            </Label>
                            <Field
                              as={Input}
                              name="email"
                              id="email"
                              placeholder="someone@domain.com"
                            />
                          </div>
                          <Button
                            type="submit"
                            disabled={isSubmitting || !dirty}
                            className="mt-4"
                          >
                            {isSubmitting ? "Inviting..." : "Invite to share"}
                          </Button>
                        </Fieldset>
                      </Form>
                    )}
                  </Formik>
                )}
              </div>
            </div>
          </SidebarSection>
        </SidebarPortal>
      )}
      {!!javascripts && (
        <div>
          <Heading>Javascript Files</Heading>
          {javascripts.length > 0 ? (
            <EmbedList items={javascripts} onRemove={refetch} />
          ) : (
            <div className="w-full bg-white dark:bg-gray-800 rounded-md p-4">
              <div className="w-full p-4 text-xl flex justify-center">
                <div className="text-gray-900 dark:text-gray-100">
                  No JavaScript files found
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {!!cssscripts && (
        <div>
          <Heading>CSS Files</Heading>
          {cssscripts.length > 0 ? (
            <EmbedList items={cssscripts} onRemove={refetch} />
          ) : (
            <div className="w-full bg-white dark:bg-gray-800 rounded-md p-4">
              <div className="w-full p-4 text-xl flex justify-center">
                <div className="text-gray-900 dark:text-gray-100">
                  No CSS files found
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {!!htmlscripts && (
        <div>
          <Heading>HTML Files</Heading>
          {htmlscripts.length > 0 ? (
            <EmbedList items={htmlscripts} onRemove={refetch} />
          ) : (
            <div className="w-full bg-white dark:bg-gray-800 rounded-md p-4">
              <div className="w-full p-4 text-xl flex justify-center">
                <div className="text-gray-900 dark:text-gray-100">
                  No HTML files found
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      <AddEmbed projectId={idNumber} />
    </div>
  );
};
export default Project;
